<template>
  <v-container fluid class="tb-layout-browse1">
    <v-row class="tb-filter-section">
      <v-row no-gutters>
        <v-col md="4">
          <div class="tb-left-second-section">
            <div>
              <h5
                v-if="switchToAdvanceFilter == true"
                @click="switchToAdvanceFilter = false"
                style="cursor: pointer"
              >
                Switch to Normal Filter
              </h5>
              <h5
                v-if="switchToAdvanceFilter == true"
                @click="switchToAdvanceFilter = true"
                style="cursor: pointer"
              >
                Switch to Advance Filter
              </h5>
            </div>
            <div>
              <h4 v-on:click="isShow = !isShow" style="float: left">
                Filter
                <v-icon left> mdi mdi-menu-down </v-icon>
              </h4>
              <span v-if="switchToAdvanceFilter == false">
                {{ conditions.length }} Filter Applied
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2">
        <v-col md="12">
          <div class="tb-normal-filter" v-show="isShow">
            <div class="tb-filter-area" v-show="switchToAdvanceFilter == false">
              <BreadTabulatorFilterable
                ref="filter"
                :model="model"
                :setConditions="setConditions"
              ></BreadTabulatorFilterable>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-row>

    <!-- defult search area-->
    <div class="tb-search-area">
      <v-row>
        <v-col md="6">
          <BreadTabulatorSearchable
            ref="search"
            :model="model"
            :setConditions="setConditions"
          ></BreadTabulatorSearchable>
          <div>
            <span id="record-count" style="float: left; font-size: 12px"></span>
          </div>
        </v-col>
        <v-col md="6" class="d-flex justify-end">
          <v-btn-toggle dense multiple class="mr-1">
            <!-- have to include data download component-->
            <BreadTabulatorExportAll
              ref="exportData"
              :model="model"
              :apiUrl="apiGetBrowseUrl()"
              @downloadData="downloadData"
            >
            </BreadTabulatorExportAll>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </div>

    <BreadTabulator
      ref="tabulatorDataTable"
      layout="fitColumns"
      :autoColumns="false"
      :paginationSize="paginationSize"
      :paginationSizeSelector="paginationSizeSelector"
      :apiUrl="apiGetBrowseUrl()"
      :columns="columns"
      :modelKey="modelKey"
      :columnSelectionDisabledCount="columnSelectionDisabledCount"
      :responsiveLayout="false"
      @getCurrentPageSize="getCurrentPageSize"
      @getCurrentSort="getCurrentSort"
      @getCurrentColumnWidth="getCurrentColumnWidth"
      @getCurrentPageNumber="getCurrentPageNumber"
    ></BreadTabulator>
  </v-container>
</template>
<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import Service from "@/objects/service";

export default {
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
      hrdc: (state) => state.hrdc.data,
    }),
  },
  props: ["params"],
  data: () => ({
    api: new Api(),
    conditions: [],
    model: new Model(),
    service: new Service(),
    listAllViews: [],
    columns: [],
    paginationSize: 10,
    paginationSizeSelector: [10, 50, 100, 200],
    modelKey: "",
    parentId: "",
    cColumnSort: [],
    displayRowCount: 10,
    selectedCustomColums: [],
    selectedAdvanceFilterConditions: [],
    selectedFilterConditions: [],
    switchToAdvanceFilter: false,
    isShow: false,
    allResizeColumns: [],
    columnSelectionDisabledCount: 2,
    currentPageNo: 1,
  }),
  created() {
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    this.modelKey = this.$route.params.modelKey;
    this.parentId = this.$route.params.parentId;

    this.columns = this.$_.filter(
      this.model.browse.table.headers,
      (headers) => {
        return headers;
      }
    );

    this.rolesAssignation(this.auth.roles);
  },
  mounted() {
    if (
      this.$route.query.viewId != "all" &&
      this.$route.query.viewId != "trashed"
    ) {
      const viewId = parseInt(this.$route.query.viewId, 10);
      this.getAllViews(viewId);
    }
    if (this.$route.query.viewId == "all") {
      this.getAllViews("all");
    }

    if (this.$route.query.viewId == "trashed") {
      this.getAllViews("trashed");
    }
  },
  methods: {
    getConditions() {
      return this.conditions;
    },
    setConditions(conditions) {
      this.selectedFilterConditions = conditions;
      this.conditions = conditions;
      this.$refs.tabulatorDataTable.getFilterCondition(conditions);
    },
    apiGetBrowseUrl() {
      let browseUrl = `${
        this.$service[this.service.key]
      }/v1/en/console/report/${this.model.child.durationAnalysisReport.key}`;

      // Check if user have salesperson role, then assign only this salesperson can see back own sales.
      if (this.isSalespersonRoles && this.auth.roles.length == 1) {
        browseUrl = browseUrl + `?email=${this.auth.email}`;
      }

      return browseUrl;
    },
    setFiltersViaConditions(conditions) {
      this.$refs.filter.setFiltersViaConditions(conditions);
    },
    getCurrentPageSize(pagesize) {
      this.displayRowCount = pagesize;
    },
    getCurrentSort(sorters) {
      this.cColumnSort = sorters;
      this.cColumnSort = sorters.map((sorter) => {
        return {
          column: sorter.field,
          dir: sorter.dir,
        };
      });
    },
    getCurrentColumnWidth(resizedColumns) {
      const resizedColumnField = resizedColumns.getField();
      const resizedColumnWidth = resizedColumns.getWidth();

      //set the resized column width and filed name to the array
      const resizedColumnWidthAndField = {
        field: resizedColumnField,
        width: resizedColumnWidth,
      };

      const index = this.allResizeColumns.findIndex((item) => {
        return item.field === resizedColumnField;
      });

      if (index !== -1) {
        this.allResizeColumns[index].width = resizedColumnWidth;
      } else {
        this.allResizeColumns.push(resizedColumnWidthAndField);
      }
    },
    changeTableDetails(listAllViews, viewId) {
      // get the view details from listAllViews where id is equal to viewId
      if (viewId == "trashed") {
        //this.$refs.tabulatorDataTable.clearTableData();
        this.$refs.tabulatorDataTable.callApiUrl(this.apiGetBrowseTrashUrl());
        this.$refs.selectColumn.showAllColumns([]);
        this.$refs.tabulatorDataTable.callSavedHeaderWidth([]);
        this.$refs.search.getFilterCondition([]); /// add this one
        this.$refs.customView.changeSelectedTabStatus("trashed", 0);
      } else if (viewId == "all") {
        //this.$refs.tabulatorDataTable.clearTableData();
        this.$refs.tabulatorDataTable.callSavedHeaderWidth([]);
        this.$refs.selectColumn.showAllColumns([]);
        this.$refs.search.getFilterCondition([]);
        this.$refs.customView.changeSelectedTabStatus("all", 1);
        this.setCurrentPageTo();
      } else {
        //console.log("other", this.$refs.tabulatorDataTable.getCurrentApiUrl());
        const tabIndex =
          listAllViews.indexOf(listAllViews.find((x) => x.id === viewId)) + 2;
        this.$refs.customView.changeSelectedTabStatus(viewId, tabIndex);
        const singleViewDetail = listAllViews.filter((item) => {
          return item.id === viewId;
        });

        if (singleViewDetail.length > 0) {
          if (
            JSON.parse(singleViewDetail[0].manageColumnCustomWidth).length > 0
          ) {
            this.$refs.tabulatorDataTable.callSavedHeaderWidth(
              JSON.parse(singleViewDetail[0].manageColumnCustomWidth)
            );
          }

          this.$refs.selectColumn.showViewSelectedColumns(
            JSON.parse(singleViewDetail[0].cColumnCondition)
          );

          // calling API
          if (JSON.parse(singleViewDetail[0].filterCondition).length > 0) {
            this.$refs.filter.setFiltersViaConditions(
              JSON.parse(singleViewDetail[0].filterCondition)
            );
          }

          // calling API
          if (JSON.parse(singleViewDetail[0].cColumnSort).length > 0) {
            this.$refs.tabulatorDataTable.callSavedColumnSort(
              JSON.parse(singleViewDetail[0].cColumnSort)
            );
          }

          // calling API
          if (singleViewDetail[0].displayRowCount != this.paginationSize) {
            this.$refs.tabulatorDataTable.callSavedPageSize(
              singleViewDetail[0].displayRowCount
            );
          }
          // add following one
          this.$refs.search.getFilterCondition(
            JSON.parse(singleViewDetail[0].filterCondition)
          );
        }

        this.setCurrentPageTo();
      }
    },
    getAllViews(viewId) {
      this.api.setMethod(`GET`);
      this.api.setUrl(
        `${this.$service.crm}/v1/en/console/crmView/list?modelKey=${this.model.key}`
      );
      this.api.setCallbackCompleted((response) => {
        // get all private views when the ouwnerUuid is equal to auth.uuid
        const privateViews = response.filter((view) => {
          return view.isPrivate === true && view.ownerUuid === this.auth.uuid;
        });

        // get all public views
        const publicViews = response.filter((view) => {
          return view.isPrivate === false;
        });

        // push all private and public views to listAllViews
        this.listAllViews = [...publicViews, ...privateViews];
        this.changeTableDetails(this.listAllViews, viewId);
      });
      this.api.fetch();
    },
    applyColumnSelection(selectedColumns) {
      this.selectedCustomColums = selectedColumns;
      this.$refs.tabulatorDataTable.applyColumnSelection(selectedColumns);
    },
    getCurrentPageNumber(pageno) {
      this.currentPageNo = pageno;
    },
    setCurrentPageTo() {
      var pageNo =
        this.$store.state.developer.data.paginationPageNo != null
          ? this.$store.state.developer.data.paginationPageNo
          : 1;
      this.$refs.tabulatorDataTable.tabulator.setPage(pageNo);
      this.$store.commit("assignDeveloperData", {
        paginationPageNo: null,
      });
    },
    downloadData(fileType, rowsType) {
      this.isLoading = true;

      switch (fileType) {
        case "csv":
          this.downloadCsvData(rowsType);
          break;
        case "xlsx":
          this.downloadExcelData(rowsType);
          break;
        case "pdf":
          this.downloadPdfData(rowsType);
          break;
        default:
          break;
      }
    },
    downloadCsvData(rowsType) {
      this.$refs.tabulatorDataTable.tabulator.download(
        "csv",
        `${this.model.key}.csv`,
        {
          sheetName: `${this.model.key}`,
        },
        rowsType == "selected" ? "selected" : "all"
      );
      this.isLoading = false;
      // unchecked all the selected rows
      this.$refs.tabulatorDataTable.tabulator.deselectRow();
    },
  },
};
</script>
<style>
.freeze-border-right {
  border-right: 1px solid black !important;
}
.claim-basis-end-border-right {
  border-right: 2px solid black !important;
}
.freeze-border-left {
  border-left: 1px solid black !important;
}
</style>
